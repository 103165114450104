@import 'css/helpers';

.section {
  position: relative;
  min-height: 100vh;
  margin-top: calc(var(--header-height) * -1);
  background: linear-gradient(
    251.84deg,
    #111827 -24.23%,
    #111826 -18.4%,
    #101726 -13.17%,
    #101725 -8.33%,
    #101624 -3.65%,
    #0f1523 1.08%,
    #0f1421 6.08%,
    #0e1420 11.58%,
    #0d121e 17.81%,
    #0c111c 24.96%,
    #0b1019 33.28%,
    #0a0e16 42.98%,
    #080c13 54.28%,
    #070a10 67.41%,
    #000 82.57%,
    #000 100%
  );
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bg-container {
    isolation: isolate;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .child {
      position: absolute;
      inset: 0;
    }
  }

  .container {
    width: 100%;
    transition: opacity 0.5s var(--ease-out-soft);

    .title {
      position: relative;
      max-width: 1040px;
      text-align: center;
      width: 100%;
      height: 140px;
      user-select: none;
      font-size: 48px;
      font-family: Quickstarter, sans-serif;
      text-shadow: 2px 4px 20px white;

      // img {
      //   object-fit: contain;
      // }
      // @include respond-to(‘tablet’) {
      //   width: 100%;
      //   height: 46px;
      // }
    }

    .text {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      margin: 24px 0 32px;
    }

    a {
      margin: auto;
      @include respond-to(‘tablet’) {
        width: 100%;
      }

      div {
        background-color: #fe00fe;
        box-shadow: 2px 2px 20px #fefefe;

        &:hover {
          box-shadow: 2px 2px 40px #fefefe;
        }

        transition: 500ms box-shadow;
      }
    }
  }
}
