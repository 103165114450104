.container {
  margin: auto;
  position: relative;
  padding: 0 var(--container-sides-space);

  &.md {
    max-width: var(--container-width);
  }

  &.lg {
    max-width: var(--container-width-lg);
  }
}
