@import '~/css/helpers';
@import 'css/variables';

.footer {
  background: linear-gradient(
    256.14deg,
    #111827 0%,
    #111826 4.7%,
    #101726 8.9%,
    #101725 12.8%,
    #101624 16.56%,
    #0f1523 20.37%,
    #0f1421 24.4%,
    #0e1420 28.83%,
    #0d121e 33.84%,
    #0c111c 39.6%,
    #0b1019 46.3%,
    #0a0e16 54.1%,
    #080c13 63.2%,
    #070a10 73.76%,
    #05070c 85.97%,
    #030407 100%
  );
  border: 1px solid rgb(31 41 55 / 0.86);
  border-radius: 32px;
  max-width: 1360px;
  width: calc(100% - 80px);
  margin: auto;
  margin-top: 160px;
  padding: 20px;
  position: relative;

  @include respond-to('tablet-lg') {
    border-left: unset;
    border-right: unset;
    border-radius: 0;
    width: 100%;
    padding: 20px var(--container-sides-space);
    margin-bottom: 0px;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    @include respond-to('tablet-lg') {
      gap: 16px;
      height: 53px;
    }

    .isotypeWrapper {
      position: relative;
      width: 52px;
      height: 58px;
      margin-left: 11px;

      @include respond-to('tablet-lg') {
        width: 27px;
        height: 30px;
      }

      img {
        object-fit: contain;
      }
    }

    .title {
      font-size: 36px;
      line-height: 48px;
      letter-spacing: -0.04em;
      font-family: FastTrack, sans-serif;

      @include respond-to('tablet-lg') {
        line-height: 1;
        font-size: 32px;
      }
    }
  }

  .line {
    border-bottom: 1px solid var(--blue-800);
  }

  .mainContent {
    display: flex;
    justify-content: space-between;
    gap: 24px 24px;
    margin: 20px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--blue-800);

    @include respond-to('tablet-lg') {
      flex-flow: wrap;
      margin-top: 32px;
    }

    @include respond-to('tablet') {
      gap: 56px;
      flex-direction: column;
    }

    .info {
      line-height: 24px;
      letter-spacing: -0.04em;
      max-width: 365px;
      min-width: 270px;

      span {
        color: var(--grey-400);
      }
    }

    .links {
      display: grid;
      grid-template-columns: repeat(4, minmax(145px, 160px));
      width: max-content;
      grid-gap: 8px;

      @include respond-to('tablet-lg') {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        grid-gap: 48px 16px;
      }

      div {
        .title {
          font-weight: 600;
          margin-bottom: 16px;
          line-height: 24px;

          @include respond-to('tablet-lg') {
            font-size: 18px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 16px;

          a {
            color: var(--grey-400);
            transition: color var(--duration-normal) var(--ease-1);
            line-height: 22px;

            &:hover,
            &:focus {
              color: var(--white);
            }
          }
        }
      }
    }
  }

  .copyright {
    line-height: 22px;
    color: var(--grey-500);

    @include respond-to('mobile-lg') {
      font-size: 12px;
    }
  }

  .footerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    @include respond-to('mobile-lg') {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .twitterLink {
    &:hover {
      opacity: 0.6;
      transform: scale(1.1);
    }

    transition: 500ms;
  }

  .socialLink {
    &:hover {
      opacity: 0.6;
      transform: scale(1.1);
    }

    margin-right: 16px;
    transition: 500ms;
  }
}
